import RingLoader from "react-spinners/RingLoader";
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>Progress Home Health</h1>
        <h2>Thank You for visiting</h2>
        <h2>Website Under Renovation</h2>
        <h2>Coming Back Soon</h2>
        <div style={{ display:'flex', justifyContent:'center'}}>
          

          
        <RingLoader color={'rgb(3, 240, 252)'}  size={150}/>
        </div>
      </div>
    </div>
  );
}

export default App;
